import {ListGroup} from 'react-bootstrap';

export default function Foot(){
  return(
    <>
    <div className="container-fluid shoe">
      <div className="container">
        <div className="row">
          <div className="col-4 copy">
            <ListGroup>
              <ListGroup.Item>&copy; 2020 Nevada Corporate Services</ListGroup.Item>
              <ListGroup.Item>Your one-stop shop for incorporating in the great state of Nevada!</ListGroup.Item>
            </ListGroup>
          </div>
          <div className="col-4 internals">
            <ListGroup defaultActiveKey="/">
              <ListGroup.Item action href="/">Home</ListGroup.Item>
            </ListGroup>
          </div>
          <div className="col-4 externals">
            <ListGroup>
              <ListGroup.Item action href="https://google.com">Google</ListGroup.Item>
              <ListGroup.Item action href="https://youtube.com">YouTube</ListGroup.Item>
              <ListGroup.Item action href="https://reddit.com">Reddit</ListGroup.Item>
            </ListGroup>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
