import {Tab, Tabs} from 'react-bootstrap';
import CategoryCards from './catcards';
import llc from '../images/mtngoat.png';
import corp from '../images/lake.png';

export default function Legalserv(){
    return(
        <>
            <div className="container">
            <Tabs defaultActiveKey="bizplan" id="bizTabs" className="mb-3">
                <Tab eventKey="bizplan" title="Living Trusts" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>What is a Living Trust?</h1>
                                <p>A living trust is a legal document that is created during a person's lifetime to manage their assets and property. The person who creates the trust is called the grantor, and they transfer their assets into the trust, which is managed by a trustee. The grantor can also be the trustee of their own living trust.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={llc} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row ">
                            <div className="col-12">
                                <CategoryCards cat="livtrust" />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="corp" title="Real Estate Privacy Trust" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>What is a Real Estate Privacy Trust?</h1>
                                <p>A real estate privacy trust, also known as a land trust or title holding trust, is a legal arrangement in which real estate is placed in a trust, and the beneficial interest in the trust is owned by a beneficiary. The trustee is the legal owner of the property and has the authority to manage and control the property on behalf of the beneficiary.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={corp} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row ">
                            <div className="col-12">
                                <CategoryCards cat="retrust" />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="bizpart" title="Estate Planning" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>What is Estate Planning?</h1>
                                <p>Estate planning is the process of creating a plan for the management and distribution of your assets after your death. This can include preparing a will, creating trusts, and designating beneficiaries for retirement accounts and life insurance policies.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={corp} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <CategoryCards cat="estates" />
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
        </>
    )
}