import './App.css';
import * as React from 'react';
import {Routes, Route, Outlet, Link} from 'react-router-dom';
import Navi from './components/navigate';
import Article from './components/article';
import Orderform from './components/order';
import Enttabs from './components/entities';
import Bizserv from './components/bizserv';
import States from './components/states';
import Legalserv from './components/legal';
import Foot from './components/footer';
import protect from './images/protect.jpg';
import cred from './images/credit.jpg';
import retrust from './images/retrust.jpg';
import ltrust from './images/ltrust.jpg';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/" element={<Basic />} />
          <Route path="/entities" element={<Entities />} />
          <Route path="/services" element={<BizServ />}></Route>
          <Route path="/states" element={<StateComp />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/protect" element={<Protect />} />
          <Route path="/business-credit" element={<Bizcred />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/commitment" element={<Commit />} />
          <Route path="/experts" element={<Expert />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Priv />} />
          <Route path="/business-plan" element={<Plan />} />
          <Route path="/401k" element={<Direct />} />
          <Route path="/estate-planning" element={<Estate />} />
          <Route path="/order" element={<Order />} />
        </Route>
      </Routes>
      <Foot />
    </div>
  );
}

function Home() {
  return (
    <>
    <header className="App-header">
      <Navi />
    </header>
    <Outlet />
    </>
  )
}

function Basic() {
  return (
    <>
    <div className="container-fluid banner">
      <div className="container bantext">
        <div className="col-10 offset-1">
          <h2>Nevada Corporate Services</h2>
          <h5>Welcome to Nevada Corporate Services, your one-stop shop for incorporating in the great state of Nevada!</h5>
          <p>Are you looking to start a new business or incorporate an existing one? Look no further than Nevada, the premier state for business incorporation. Our team of experienced professionals can help guide you through the process of creating a corporation in Nevada, taking advantage of all the benefits the state has to offer.</p>        
        </div>
      </div>
    </div>
    <Article article="index" />
    </>
  )
}

function Entities() {
  return (
    <>
      <Enttabs />
    </>
  )
}

function BizServ() {
  return (
    <>
    <div className="text-center">
      <Bizserv />
    </div>
    </>
  )
}

function StateComp() {
  return (
    <>
      <div className="container statecards">
        <States />
      </div>
    </>
  )
}

function Legal() {
  return (
    <>
      <div className="container text-center">
        <Legalserv />
      </div>
    </>
  )
}

function Protect() {
  return (
    <>
      <div className="container-fluid banner">
        <div className="container bantext">
          <h2>Statement</h2>
          <h5>More info about the above statement.</h5>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center artbox">
          <div className="col-3"><img src={protect} alt="Testing" className="tallpic" /></div>
          <div className="col-9" style={{lineHeight:2.5}}>
            <Article article="assetpro" />
          </div>
        </div>
      </div>
    </>
  )
}

function Bizcred() {
  return (
    <>
      <div className="container-fluid banner">
        <div className="container bantext">
          <h2>Statement</h2>
          <h5>More info about the above statement.</h5>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center artbox">
          <div className="col-3"><img src={cred} alt="Testing" className="tallpic" /></div>
          <div className="col-9" style={{lineHeight:1.4}}>
            <Article article="bizcred" />
          </div>
        </div>
      </div>
    </>
  )
}

function Faq() {
  return (
    <>
      <div className="container-fluid banner">
        <div className="container bantext">
          <h5>At NV Corporate Services, our mission is to provide exceptional corporate solutions and support to our clients, enabling them to thrive in the dynamic business environment. We are committed to delivering comprehensive and innovative services that meet their unique needs, while upholding the highest standards of professionalism, integrity, and confidentiality</h5>
        </div>
      </div>
      <Article article="nvadd" />
      <hr />
      <Article article="states" />
      <hr />
      <Article article="corpveil" />
      <hr />
      <Article article="procon" />
    </>
  )
}

function Commit() {
  return (
    <>
      <div className="container-fluid banner"></div>
      <Article article="mission" />
    </>
  )
}

function Expert() {
  return (
    <>
      <div className="container-fluid banner"></div>
      <Article article="expert" />
    </>
  )
}

function Contact() {
  return (
    <>
      <div className="container-fluid banner"></div>
      <Article article="bizplan" />
    </>
  )
}

function Priv() {
  return (
    <>
      <div className="container-fluid banner"></div>
      <Article article="privacy" />
    </>
  )
}

function Order() {
  return (
    <>
      <div className="container-fluid orderbanner">
        <div className="container bantext">
          <h2>Start Your Protection Now!</h2>
        </div>
      </div>
      <Orderform />
    </>
  )
}

function Direct() {
  return (
    <>
      <div className="container-fluid banner">
        <div className="container bantext">
          <h2>Statement</h2>
          <h5>More info about the above statement.</h5>
        </div>
      </div>
      <Article article="401k" />
    </>
  )
}

function Plan() {
  return (
    <>
      <div className="container-fluid banner">
        <div className="container bantext">
          <h2>Statement</h2>
          <h5>More info about the above statement.</h5>
        </div>
      </div>
      <Article article="bizplan" />
    </>
  )
}

function Estate() {
  return (
    <>
      <div className="container-fluid banner">
        <div className="container bantext">
          <h2>Statement</h2>
          <h5>More info about the above statement.</h5>
        </div>
      </div>
      <Article article="estate" />
    </>
  )
}

export default App;
