import {Tab, Tabs} from 'react-bootstrap';
import CategoryCards from './catcards';
import llc from '../images/mtngoat.png';
import corp from '../images/lake.png';

export default function Bizserv(){
    return(
        <>
        <div className="container">
            <Tabs defaultActiveKey="bizplan" id="bizTabs" className="mb-3">
                <Tab eventKey="bizplan" title="Business Plans" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2>Business Plans</h2>
                                <p>A business plan is a comprehensive document that outlines the objectives, strategies, and financial projections of a business. It is a crucial tool for entrepreneurs and small business owners to attract investors, secure financing, and set a clear roadmap for their business. If you are considering starting a new business or expanding an existing one, investing in a professional business plan can be an excellent decision.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={llc} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row ">
                            <div className="col-12">
                                <CategoryCards cat="whybizplan" />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="cred" title="Corporate Credit" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2>Corporate Credit</h2>
                                <p>Corporate credit refers to the credit that a business can obtain in its own name, separate from the personal credit of its owners or shareholders. We specialize in helping businesses obtain the credit they need to succeed. We understand that securing financing can be a challenge for businesses of all sizes, and we're here to help.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={corp} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row ">
                            <div className="col-12">
                                <CategoryCards cat="creds" />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="asspro" title="Asset Protection" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2>Asset Protection</h2>
                                <p>Asset protection is the practice of safeguarding one's wealth and assets from legal claims, creditor actions, and potential loss due to various risks such as lawsuits, bankruptcy, or divorce. The goal of asset protection is to protect one's assets from being taken or significantly reduced in value due to unexpected events or claims.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={corp} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <CategoryCards cat="asspro" />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="401" title="401(k) Direct" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2>401(k) Direct</h2>
                                <p>A 401(k) plan is a retirement savings plan that allows employees to contribute a portion of their earnings into a tax-advantaged investment account. There are two main types of 401(k) plans: traditional 401(k) plans and Roth 401(k) plans. In this article, we will focus on the traditional 401(k) plan, specifically the 401k direct option.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={corp} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <CategoryCards cat="401k" />
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
        </>
    )
}