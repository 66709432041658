import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import nvflag from '../images/nvflag.png';
import wyflag from '../images/wyflag.webp';
import sdflag from '../images/sdflag.webp';
import akflag from '../images/akflag.png';
import flflag from '../images/flflag.webp';
import mtflag from '../images/mtflag.webp';
import nhflag from '../images/nhflag.webp';
import txflag from '../images/txflag.png';
import utflag from '../images/utflag.png';

export default function States(){
    return (
        <>
        <div class="text-center">
            <h1>How Do Different States Compare?</h1>
            <p>When it comes to starting a business, choosing the right state to incorporate in can have a significant impact on your tax burden and overall business success. Each state has its own set of tax laws, regulations, and business incentives that can affect the profitability of your business. In this article, we will rank some of the most popular business states based on their business tax benefits and compare each state to Nevada, which is known for its favorable business climate.</p>
        </div>
        <div class="row" className="d-flex align-items-center">
            <CardGroup>
                <Card style={{width:'100%'}}>
                    <Card.Img variant="top" src={nvflag}></Card.Img>
                    <Card.Body>
                        <Card.Title>Nevada</Card.Title>
                        <Card.Text>Nevada is consistently ranked as one of the top states for business tax benefits. The state has no personal or corporate income tax, no franchise tax, and no inventory tax. Additionally, Nevada has low property tax rates and no unitary tax, making it an attractive location for businesses of all sizes.</Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{width:'100%'}}>
                    <Card.Img variant="top" src={wyflag}></Card.Img>
                    <Card.Body>
                        <Card.Title>Wyoming</Card.Title>
                        <Card.Text>Wyoming is another state that offers a favorable tax climate for businesses. The state has no corporate or individual income tax, and it has one of the lowest sales tax rates in the country. Additionally, Wyoming has a low property tax rate and offers various business incentives and exemptions.</Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{width:'100%'}}>
                    <Card.Img variant="top" src={sdflag}></Card.Img>
                    <Card.Body>
                        <Card.Title>South Dakota</Card.Title>
                        <Card.Text>South Dakota is a tax-friendly state for businesses, with no corporate or individual income tax, no personal property tax, and a low sales tax rate. The state also has various business incentives, including tax credits for new job creation and property tax exemptions for certain types of property.</Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
        <div class="row">
            <CardGroup>
                <Card style={{width:'100%'}}>
                    <Card.Img variant="top" src={akflag}></Card.Img>
                    <Card.Body>
                        <Card.Title>Alaska</Card.Title>
                        <Card.Text>Alaska has no individual or corporate income tax, no statewide sales tax, and no personal property tax. Additionally, the state offers various tax credits and incentives for businesses, such as the Alaska Economic Development Grant and the Alaska Industrial Development and Export Authority.</Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{width:'100%'}}>
                    <Card.Img variant="top" src={flflag}></Card.Img>
                    <Card.Body>
                        <Card.Title>Florida</Card.Title>
                        <Card.Text>Florida is a popular destination for businesses due to its favorable tax climate. The state has no individual income tax, no corporate income tax on limited liability companies, and a low sales tax rate. Additionally, Florida offers various business incentives and exemptions, including the Qualified Target Industry Tax Refund and the Capital Investment Tax Credit.</Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{width:'100%'}}>
                    <Card.Img variant="top" src={mtflag}></Card.Img>
                    <Card.Body>
                        <Card.Title>Montana</Card.Title>
                        <Card.Text>Montana has no sales tax and no gross receipts tax, making it a tax-friendly state for businesses. Additionally, Montana has a low individual income tax rate and various business incentives and exemptions, such as the Big Sky Economic Development Trust Fund and the Montana Coal Tax Trust Fund.</Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
        <div class="row">
            <CardGroup>
                <Card style={{width:'100%'}}>
                    <Card.Img variant="top" src={nhflag}></Card.Img>
                    <Card.Body>
                        <Card.Title>New Hampshire</Card.Title>
                        <Card.Text>New Hampshire has no sales tax or individual income tax, but it does have a corporate income tax. However, the state offers various business incentives and exemptions, including the Job Training Fund and the Research and Development Tax Credit.</Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{width:'100%'}}>
                    <Card.Img variant="top" src={txflag}></Card.Img>
                    <Card.Body>
                        <Card.Title>Texas</Card.Title>
                        <Card.Text>Texas has no individual income tax, and it is known for its business-friendly climate. The state has a low corporate income tax rate, and it offers various business incentives and exemptions, such as the Texas Enterprise Fund and the Texas Emerging Technology Fund.</Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{width:'100%'}}>
                    <Card.Img variant="top" src={utflag}></Card.Img>
                    <Card.Body>
                        <Card.Title>Utah</Card.Title>
                        <Card.Text>Utah has a low corporate income tax rate, and it offers various business incentives and exemptions, such as the Economic Development Tax Increment Financing program and the Industrial Assistance Fund. The state also has a low sales tax rate and no inventory tax.</Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
    </>
    )
}