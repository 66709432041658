import React, {useState} from 'react';
import Card from 'react-bootstrap/Card';
import {Button, Modal} from 'react-bootstrap';

export default function CategoryCards(props){
    if (props.cat === 'whyllc'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>Why form an LLC?</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>Forming an LLC offers several benefits over other business structures. First and foremost, it protects your personal assets. In the event that your LLC incurs debts or legal issues, your personal assets (such as your home or car) are generally shielded from creditors or legal judgments. This provides added peace of mind and financial security for business owners.</Card.Text>
                        <Card.Text><LLCInfoModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'howllc'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>How do you form an LLC?</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>The process for forming an LLC varies by state, but generally involves filing articles of organization with the state's secretary of state, and paying a fee. In addition, you'll need to choose a name for your LLC, designate a registered agent, and determine how the LLC will be managed (either by members or by designated managers).</Card.Text>
                        <Card.Text><LLCHowModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'whycorp'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>Why form a Corporation?</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>Forming a corporation can offer several benefits over other business structures. First and foremost, it protects the personal assets of the shareholders. In the event that the corporation incurs debts or legal issues, the shareholders are not personally liable for the company's obligations. This provides added peace of mind and financial security for business owners.</Card.Text>
                        <Card.Text><CorpInfoModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'howcorp'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>How do you form a Corporation?</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>The process for forming a corporation varies by state, but generally involves filing articles of incorporation with the state's secretary of state, and paying a fee. In addition, you'll need to choose a name for your corporation, designate a registered agent, and determine how the corporation will be managed. You'll also need to create bylaws that set out the rules and regulations for how the corporation will operate.</Card.Text>
                        <Card.Text><CorpHowModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'bizpart'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>Business Partnership</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>There are two main types of partnerships: general partnerships and limited partnerships. In a general partnership, all partners have equal management authority and are personally liable for the debts and legal issues of the business. In a limited partnership, there are two types of partners: general partners who have management authority and personal liability, and limited partners who have limited liability and do not participate in management.</Card.Text>
                        <Card.Text><BizpartModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'procon'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>Corporations vs. LLCs</Card.Title>
                        <Card.Subtitle><h5>Advantages and Disadvantages</h5></Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text><LLCModal /></Card.Text>
                        <Card.Text><CorpModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'sole'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>Sole Proprietorships</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text><p>As a sole proprietor, the owner has complete control over the business, and there is no separation between the business's assets and the owner's personal assets. This means that the owner is personally liable for any debts or legal issues that the business incurs.</p></Card.Text>
                        <Card.Text><SoleModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'nonprof'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>Non-Profits</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>Nonprofits are organized as corporations or associations, and are often granted tax-exempt status by the government, meaning they are not required to pay federal income taxes on their income. This status is usually granted to organizations that serve a charitable, educational, religious, or scientific purpose.</Card.Text>
                        <Card.Text><NonprofModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'whybizplan'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>Why Do You Need a Business Plan?</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <ul>
                                <li>Helps clarify your vision - A business plan forces you to articulate your business idea in a clear and concise way. It can help you identify your target market, unique value proposition, and overall business goals.</li>
                                <li>Helps secure funding - Whether you are seeking funding from investors or applying for a loan, a comprehensive business plan is essential. A well-written plan can help you convince potential investors or lenders that your business is worth investing in.</li>
                                <li>Provides a roadmap for your business - A business plan outlines the steps you need to take to achieve your business goals. It includes detailed financial projections, marketing strategies, and operational plans.</li>
                                <li>Helps you make informed decisions - A business plan is a living document that can help you make informed decisions about your business. It allows you to track your progress, identify areas of improvement, and make adjustments to your strategies as needed.</li>
                                <li>Increases your chances of success - According to a study by the Small Business Administration, businesses with a written business plan are more likely to succeed than those without one. This is because a business plan forces you to think critically about your business and develop a strategic plan for success.</li>
                            </ul>
                            <p>Investing in a professional business plan can be a wise decision for any entrepreneur or small business owner. It can provide numerous benefits, including securing funding, clarifying your vision, and providing a roadmap for success. At our website, we offer customized business plans that are tailored to your specific business needs. Our team of experienced business plan writers will work closely with you to create a comprehensive plan that will help you achieve your business goals. Contact us today to learn more.</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'creds'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>What is Corporate Credit?</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <ul>
                                <li>Separation of personal and business finances - By establishing corporate credit, a business can separate its financial activities from those of its owners or shareholders, reducing the risk of personal financial liability for the business's debts.</li>
                                <li>Access to financing - With good corporate credit, a business can qualify for loans and lines of credit, providing it with the capital it needs to operate and grow.</li>
                                <li>Lower interest rates - Businesses with good credit scores can often qualify for lower interest rates on loans and other forms of financing, reducing the cost of borrowing.</li>
                                <li>Better supplier terms - With good corporate credit, a business can negotiate better terms with suppliers, such as extended payment periods or discounts for early payment.</li>
                                <li>Improved cash flow - By establishing credit, a business can improve its cash flow by deferring payments for goods and services until a later date.</li>
                                <li>Increased credibility - Having good credit can enhance a business's reputation and credibility, making it more attractive to investors and customers.</li>
                            </ul>
                            <p>Overall, having corporate credit can provide a number of benefits for a business, including increased access to financing, improved cash flow, and enhanced credibility. It is important for businesses to establish and maintain good credit in order to take advantage of these benefits and ensure long-term success.</p>
                        </Card.Text>
                        <Card.Text><CreditModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'asspro'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>Why Do You Need to Protect Your Assets?</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <p>There are many different strategies and tools available to achieve asset protection, including the creation of legal entities such as corporations, limited liability companies (LLCs), trusts, and the use of insurance policies.</p>
                            <p>The benefits of asset protection can include:</p>
                            <ul>
                                <li>Protection from creditors - Asset protection strategies can shield assets from creditors, protecting them from being seized or reduced in value due to legal claims or debt collection efforts.</li>
                                <li>Protection from lawsuits - Asset protection can protect assets from being lost or significantly reduced in value due to lawsuits or other legal claims.</li>
                                <li>Tax benefits - Certain asset protection strategies can offer tax benefits, such as reducing estate taxes or providing tax deductions for contributions to certain types of trusts or retirement plans.</li>
                                <li>Peace of mind - Asset protection can provide peace of mind and security knowing that one's assets are protected and their financial future is secure.</li>
                                <li>Estate planning benefits - Asset protection can also be a key component of estate planning, helping to ensure that one's assets are passed on to their beneficiaries according to their wishes.</li>
                            </ul>
                            <p>It's important to note that asset protection strategies must be established before a legal claim or other risk arises. Attempting to shield assets after a claim or legal action has been taken can be seen as fraudulent and may not be effective.</p>
                        </Card.Text>
                        <Card.Text><AssProModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === '401k'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>What's a 401(k) Direct Plan?</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <p>A 401k direct plan is a type of 401(k) plan that allows employees to direct their own investments. With a 401k direct plan, employees have the ability to choose their own investments from a range of options provided by their employer. This is in contrast to other types of 401(k) plans, where investments are chosen and managed by a financial advisor or the plan sponsor.</p>
                        </Card.Text>
                        <Card.Text><K401Modal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'estates'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>Benefits of Estate Planning</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <ul>
                                <li>Asset protection - Proper estate planning can help protect your assets from creditors, lawsuits, and other legal issues that can arise after your death.</li>
                                <li>Minimize taxes - With proper estate planning, you can reduce the amount of taxes that your heirs will have to pay, ensuring that they receive as much of your estate as possible.</li>
                                <li>Avoid probate - By creating a living trust or other estate planning tools, you can avoid probate, which can be a costly and time-consuming process.</li>
                                <li>Provide for your family - Estate planning allows you to ensure that your family is provided for after your death. You can designate guardians for minor children, create trusts to provide for family members with special needs, and ensure that your loved ones are taken care of financially.</li>
                                <li>Maintain privacy - By creating a living trust, you can ensure that your assets and their distribution remain private, as living trusts are not subject to public record.</li>
                                <li>Peace of mind - Knowing that you have a plan in place for the management and distribution of your assets can provide peace of mind, both for you and your loved ones.</li>
                            </ul>
                            <p>Overall, estate planning is an important step for anyone who wants to protect their assets, provide for their family, and ensure that their wishes are carried out after their death. By working with an experienced estate planning attorney, you can create a plan that meets your specific needs and provides peace of mind for you and your loved ones.</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'retrust'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>The Purpose of a Real Estate Privacy Trust</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <p>The primary purpose of a real estate privacy trust is to provide anonymity for the beneficiary. When the property is held in the trust, the trustee's name is listed as the owner of the property on public records, while the beneficiary's name is kept confidential. This allows the beneficiary to maintain their privacy and avoid unwanted attention or inquiries related to the property ownership.</p>
                        </Card.Text>
                        <Card.Text><ReptModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    } else if (props.cat === 'livtrust'){
        return (
            <>
                <Card className="bg-dark text-white">
                    <Card.Header>
                        <Card.Title>The Difference Between a Will and a Living Trust</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <p>A living trust is different from a will because it takes effect during the grantor's lifetime, and it can continue to manage their assets even after their death. The trustee is responsible for managing the assets in the trust and distributing them to the beneficiaries according to the grantor's instructions.</p>
                        </Card.Text>
                        <Card.Text><LivModal /></Card.Text>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

function LLCInfoModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn More!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>More Information About LLCs</Modal.Header>
                <Modal.Body>
                <p>LLCs also offer more flexibility and simplicity than corporations. They typically have fewer reporting and management requirements than corporations, and members can participate in the management of the business without being subject to the same formalities required of corporate directors and officers. Additionally, LLCs offer pass-through taxation, meaning that the profits and losses of the business flow through to the owners' personal tax returns, which can result in significant tax savings.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function LLCHowModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn More!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>More Information About LLCs</Modal.Header>
                <Modal.Body>
                    <p>Once your LLC is formed, you'll need to obtain any necessary licenses and permits, and comply with state and federal regulations. It's also important to keep accurate records and maintain your LLC in good standing by filing annual reports and paying any necessary fees.</p>
                    <p>Overall, forming an LLC can be a great choice for small business owners looking for liability protection, flexibility, and tax benefits. If you're interested in forming an LLC, be sure to consult with an attorney or accountant to ensure that you fully understand the process and requirements.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function CorpInfoModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn More!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>More Information About Corporations</Modal.Header>
                <Modal.Body>
                    <p>Corporations can also raise capital more easily than other business structures. They can sell stock to investors in order to raise funds, and are generally seen as more stable and trustworthy than other business structures, which can make them more attractive to investors.</p>
                    <p>Corporations also offer more flexibility and structure than other business structures. They typically have formal management structures, with a board of directors and officers, which can help ensure that the business is run efficiently and effectively. They may also have more opportunities for growth and expansion, since they can sell stock to raise funds and hire employees.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function CorpHowModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn More!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>More Information About Corporations</Modal.Header>
                <Modal.Body>
                    <p>Once your corporation is formed, you'll need to obtain any necessary licenses and permits, and comply with state and federal regulations. It's also important to keep accurate records and maintain your corporation in good standing by holding regular board meetings and filing annual reports.</p>
                    <p>Overall, forming a corporation can be a great choice for business owners looking for liability protection, flexibility, and the ability to raise capital. If you're interested in forming a corporation, be sure to consult with an attorney or accountant to ensure that you fully understand the process and requirements.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function LLCModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Show Me the Advantages and Disadvantages of an LLC
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>Advantages and Disadvantages of LLCs</Modal.Header>
                <Modal.Body>
                    <p>LLCs (Limited Liability Companies) are a popular business structure because they offer a combination of liability protection, flexibility, and tax benefits. However, like any business structure, there are both advantages and disadvantages to forming an LLC. Here are some of the main ones:</p>
                    <p>Advantages of an LLC:</p>
                    <ol>
                        <li>Limited Liability Protection: LLCs offer personal liability protection for their owners, meaning that the owners are not personally responsible for the company's debts or legal issues. This means that their personal assets, such as their home or car, are generally not at risk if the LLC is sued or incurs debts.</li>
                        <li>Tax Flexibility: LLCs offer pass-through taxation, which means that the profits and losses of the business flow through to the owners' personal tax returns. This can result in significant tax savings, as the owners are only taxed on their share of the profits.</li>
                        <li>Flexible Management: LLCs offer more flexibility than other business structures, as the owners can participate in the management of the business without being subject to the same formalities required of corporate directors and officers.</li>
                        <li>Easy to Form: LLCs are relatively easy to form and require fewer formalities than corporations. In most states, you can form an LLC by filing articles of organization with the state and paying a fee.</li>
                    </ol>
                    <hr />
                    <p>Disadvantages of an LLC:</p>
                    <ol>
                        <li>Limited Life: LLCs are often seen as a short-term business structure because they typically have a limited life span. In many states, an LLC must be dissolved after a certain period of time or upon the death of one of the owners.</li>
                        <li>Self-Employment Taxes: While LLCs offer pass-through taxation, owners may still be subject to self-employment taxes on their share of the profits.</li>
                        <li>Complexity: While LLCs are generally less complex than corporations, they can still be more complex than other business structures such as sole proprietorships or partnerships. This can result in higher costs for formation and ongoing management.</li>
                        <li>Difficulty Raising Capital: LLCs may have difficulty raising capital because they cannot sell stock in the same way that corporations can. This can make it harder for LLCs to attract investors and raise funds for growth and expansion.</li>
                    </ol>
                    <p>Overall, the advantages of an LLC often outweigh the disadvantages for small business owners looking for liability protection, flexibility, and tax benefits. However, it's important to carefully consider the pros and cons and consult with an attorney or accountant before deciding on a business structure.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function CorpModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Show Me the Advantages and Disadvantages of a Corporation
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>Advantages and Disadvantages of Corporations</Modal.Header>
                <Modal.Body>
                <h5>Corporations</h5>
                    <p>Corporations are a popular business structure for both small and large companies. They offer several benefits over other business structures, but they also come with some drawbacks. Here are some of the main advantages and disadvantages of forming a corporation:</p>
                    <p>Advantages of a Corporation</p>
                    <ol>
                        <li>Limited Liability Protection: The primary advantage of forming a corporation is the limited liability protection it provides to its owners. Shareholders are not personally responsible for the corporation's debts or legal issues, which means that their personal assets are generally not at risk if the corporation is sued or incurs debts.</li>
                        <li>Easier Access to Capital: Corporations can raise capital more easily than other business structures, since they can sell stock to investors in order to raise funds. This makes it easier for corporations to finance growth and expansion.</li>
                        <li>Perpetual Existence: A corporation has perpetual existence, meaning that it can continue to operate even if one or more of its shareholders dies or leaves the company.</li>
                        <li>Professional Image: Corporations are often viewed as more professional and established than other business structures, which can help build credibility with customers, suppliers, and investors.</li>
                    </ol>
                    <p>Disadvantages of a Corporation</p>
                    <ol>
                        <li>Complexity and Formality: Corporations are more complex and formal than other business structures, and they require more paperwork, record-keeping, and legal and accounting expertise. This can result in higher formation and ongoing management costs.</li>
                        <li>Double Taxation: Corporations are subject to double taxation, meaning that they are taxed on their profits at the corporate level, and then shareholders are taxed on their dividends and capital gains. This can result in higher tax bills for both the corporation and its shareholders.</li>
                        <li>Loss of Control: Shareholders of a corporation may have less control over the company than owners of other business structures, since the corporation is run by a board of directors.</li>
                        <li>Compliance Requirements: Corporations are subject to more regulatory and compliance requirements than other business structures, which can result in higher costs and more time-consuming paperwork.</li>
                    </ol>
                    <p>Overall, corporations are a good choice for businesses that need to raise capital, want to protect their owners from personal liability, and are willing to comply with the formalities and regulations that come with this business structure. However, they are not the best choice for all businesses, and owners should carefully consider the advantages and disadvantages before deciding on a business structure. It's important to consult with an attorney or accountant to fully understand the pros and cons of forming a corporation.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function BizpartModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn More!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>More Information About Business Partnerships</Modal.Header>
                <Modal.Body>
                    <p>Partnerships are a popular business structure for small businesses, particularly those with multiple owners who want to share in the risks and rewards of running a business. Partnerships offer several advantages, including shared decision-making and financial resources, flexibility in management and ownership structure, and pass-through taxation (in which profits and losses are reported on the partners' personal tax returns).</p>
                    <p>However, partnerships also come with some potential drawbacks. Partners may have disagreements over management or finances, and personal liability for the business's debts and legal issues can be a significant risk. Additionally, partnerships may have a limited lifespan if one or more partners leave the business or pass away, which can create additional management and ownership issues.</p>
                    <p>Overall, partnerships can be a good choice for small businesses with multiple owners who want to share in the management and profits of the business, but it's important to carefully consider the advantages and disadvantages before deciding on a business structure. It's recommended to consult with an attorney or accountant to fully understand the pros and cons of forming a partnership.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function SoleModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn More!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>More Information About Sole Proprietorships</Modal.Header>
                <Modal.Body>
                    <p>Sole proprietorships are one of the simplest and most common business structures, and they are often used by small businesses and self-employed individuals. This is because they are easy and inexpensive to set up and maintain, with few legal formalities or regulatory requirements.</p>
                    <p>Additionally, as a sole proprietor, the owner has full ownership and control of the business, including all profits generated by the business. The owner can also make decisions quickly and easily without having to consult with partners or a board of directors.</p>
                    <p>However, there are some disadvantages to operating as a sole proprietorship. For example, the owner's personal assets are at risk if the business faces financial or legal problems. Additionally, it may be difficult to raise capital or obtain financing as a sole proprietor since lenders may view the business as less stable or less able to pay back loans.</p>
                    <p>Overall, a sole proprietorship can be a good choice for individuals who want to start and run a small business on their own, but it's important to carefully consider the advantages and disadvantages before deciding on a business structure. It's recommended to consult with an attorney or accountant to fully understand the pros and cons of forming a sole proprietorship.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function NonprofModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn More!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>More Information About Non-Profits</Modal.Header>
                <Modal.Body>
                    <p>Nonprofits can be funded by a variety of sources, including donations, grants, and fees for services provided. They are typically governed by a board of directors or trustees, who oversee the organization's operations and ensure that it is fulfilling its mission.</p>
                    <p>Nonprofits have several advantages over for-profit businesses, such as access to grants and donations, tax exemptions, and a sense of purpose that can attract passionate volunteers and employees. However, they also face challenges, such as a lack of funding, limited resources, and the need to continually demonstrate their impact and effectiveness to donors and the community.</p>
                    <p>Overall, nonprofits play an important role in society by addressing important social and community needs, and providing essential services and support to vulnerable populations. They are a vital part of the economy and provide an opportunity for individuals and groups to make a meaningful difference in their communities.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function CreditModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn About the Types of Business Credit!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>Credit Options</Modal.Header>
                <Modal.Body>
                    <p>We specialize in helping businesses obtain the credit they need to succeed. We understand that securing financing can be a challenge for businesses of all sizes, and we're here to help.</p>
                    <p>We offer a range of business credit products designed to meet the needs of companies in various industries. Our team of financial experts can help you determine which type of credit is best for your business, and work with you to ensure that you meet the requirements for approval.</p>
                    <p>Here are just a few of the business credit options that we offer:</p>
                    <ul>
                        <li>Business lines of credit - A line of credit allows businesses to access funds as needed, up to a predetermined limit. This type of credit is ideal for businesses that have fluctuating cash flow needs, as it provides a flexible source of financing.</li>
                        <li>Business credit cards - Business credit cards offer a convenient way to pay for expenses and build credit. With features such as cashback rewards and expense tracking tools, these cards can be a valuable tool for businesses of all sizes.</li>
                        <li>Equipment financing - For businesses that need to purchase or lease equipment, we offer equipment financing options. This type of credit allows businesses to acquire the equipment they need without having to pay for it upfront.</li>
                        <li>Invoice financing - Invoice financing allows businesses to borrow against their outstanding invoices, providing a source of working capital while waiting for customers to pay.</li>
                    </ul>
                    <p>We understand that each business has unique needs, and we work closely with our clients to ensure that they get the credit products that are right for them. Our goal is to help businesses thrive by providing them with the financing they need to grow and succeed.</p>
                    <p>If you're interested in learning more about our business credit options, please browse our website or contact us today. We're here to help you succeed.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function AssProModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn About What Can Happen When You Fail to Adequately Protect Your Assets!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>What If I Don't Protect My Assets?</Modal.Header>
                <Modal.Body>
                    <p>Failing to protect your assets can lead to a range of potential dangers, including:</p>
                    <ul>
                        <li>Lawsuits: If you operate a business or own property, you are at risk of being sued. A lawsuit can result in a significant financial loss and even bankruptcy if you are found liable for damages.</li>
                        <li>Personal Liability: If you do not have proper asset protection in place, your personal assets, such as your home and savings, could be at risk if your business is sued.</li>
                        <li>Creditors: If you owe money to creditors, they may be able to go after your personal assets if you have not taken steps to protect them.</li>
                        <li>Divorce: If you go through a divorce, your assets could be at risk if they are not properly protected. Your spouse may be entitled to a portion of your assets, which could result in significant financial losses.</li>
                        <li>Estate Planning: If you do not have a proper estate plan in place, your assets could be subject to estate taxes and may not be distributed according to your wishes.</li>
                    </ul>
                    <p>In summary, not protecting your assets can result in serious financial and legal consequences. By taking steps to protect your assets, such as setting up a business entity, creating a trust, and developing an estate plan, you can help to safeguard your assets and minimize the risks of financial loss.</p>
                    <p>Overall, asset protection is an important consideration for anyone looking to protect their wealth and assets. It's important to work with a qualified attorney or financial advisor to determine the best asset protection strategies for your individual needs and circumstances.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function K401Modal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn About All of the Benefits a 401(k) Can Bring!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>401(k) Direct Plan</Modal.Header>
                <Modal.Body>
                    <ul>
                        <li>Greater control - With a 401k direct plan, employees have greater control over their investments. They can choose the specific stocks, bonds, mutual funds, or other investments that they believe will best meet their individual financial goals.</li>
                        <li>Lower fees - Because employees are managing their own investments, the fees associated with a 401k direct plan are often lower than those associated with other types of 401(k) plans.</li>
                        <li>Flexibility - With a 401k direct plan, employees have the flexibility to adjust their investments as needed, based on changes in the market or in their personal financial goals.</li>
                        <li>Tax benefits - Like other types of 401(k) plans, a 401k direct plan offers tax benefits, including the ability to defer taxes on contributions until retirement, when tax rates may be lower.</li>
                    </ul>
                    <p>It's important to note that a 401k direct plan may not be the best option for everyone. Managing investments requires a certain level of financial knowledge and experience, and not everyone may feel comfortable making investment decisions on their own. Additionally, a 401k direct plan may not be offered by all employers.</p>
                    <p>Overall, a 401k direct plan can be a valuable tool for employees who want greater control over their retirement savings and have the financial knowledge and experience to manage their own investments. It's important to carefully consider your individual financial goals and needs when choosing the right retirement savings plan for you.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function ReptModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn About the Benefits of a Real Estate Privacy Trust!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>Benefits of a Real Estate Privacy Trust</Modal.Header>
                <Modal.Body>
                    <ul>
                        <li><strong>Enhanced privacy</strong> - The use of a real estate privacy trust allows the beneficiary to maintain their anonymity and keep their name off public records.</li>
                        <li><strong>Estate planning</strong> - A real estate privacy trust can be an effective estate planning tool, allowing for the easy transfer of the property to the beneficiary upon the death of the grantor.</li>
                        <li><strong>Asset protection</strong> - A real estate privacy trust can provide protection for the property against potential legal claims or creditors.</li>
                        <li><strong>Avoidance of probate</strong> - Since the property is held in the trust, it can be distributed to the beneficiary upon the grantor's death without going through probate.</li>
                        <li><strong>Flexibility</strong> - The trust can be easily modified or revoked by the grantor during their lifetime.</li>
                    </ul>
                    <p>It is important to note that a real estate privacy trust is not a complete substitute for legal advice, and it may not be appropriate for all situations. It is always recommended to consult with an attorney to determine if a real estate privacy trust is a suitable option for your specific circumstances.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function LivModal(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <Button variant="primary" onClick={handleShow}>
                Click Here to Learn About the Pros and Cons of a Living Trust!
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>Pros and Cons of a Living Trust</Modal.Header>
                <Modal.Body>
                    <h4>Advantages of Creating a Living Trust</h4>
                    <ol>
                        <li><strong>Avoiding probate</strong> - Since the assets in the trust are not part of the grantor's probate estate, they can be distributed to the beneficiaries without going through the costly and time-consuming probate process.</li>
                        <li><strong>Privacy</strong> - Unlike a will, which becomes part of the public record, a living trust is a private document that is not filed with the court.</li>
                        <li><strong>Flexibility</strong> - The grantor can modify or revoke the living trust at any time during their lifetime.</li>
                        <li><strong>Management of assets</strong> - If the grantor becomes incapacitated, the trustee can manage the assets in the trust on their behalf, avoiding the need for a court-appointed guardian.</li>
                    </ol>
                    <hr />
                    <h4>Disadvantages of Creating a Living Trust</h4>
                    <ol>
                        <li><strong>Cost</strong> - Creating a living trust can be more expensive than creating a will, as it requires the transfer of assets into the trust and the ongoing management of the trust by a trustee.</li>
                        <li><strong>Time-consuming</strong> - Creating a living trust can be a time-consuming process, as it involves transferring assets into the trust and creating a plan for managing those assets.</li>
                        <li><strong>Not a complete substitute for a will</strong> - While a living trust can help avoid probate, it is still important to have a will to address other issues, such as the appointment of a guardian for minor children.</li>
                    </ol>
                    <p>Overall, a living trust can be a useful tool for managing assets and avoiding probate, but it is important to carefully consider the potential benefits and drawbacks before deciding whether to create one. It may be a good idea to consult with an attorney or financial planner to determine whether a living trust is right for you.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}