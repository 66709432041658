// import { useEffect, useState } from "react";

export default function Article(props) {
    // const [article, setArticle] = useState({});
    // const [refresh, setRefresh] = useState(false);

    // useEffect(() => {
    //     if (props.article === 'index'){
    //         console.log("Fetching Index Article");
    //         fetch('http://localhost:5000/indexarticle')
    //         .then(function(response){return response.json();})
    //         .then(function(data){setArticle({
    //             title: data[0].headline,
    //             sub: data[0].subtitle,
    //             pone: data[0].pone,
    //             ptwo: data[0].ptwo,
    //             pthree: data[0].pthree,
    //             pfour: data[0].pfour,
    //             pfive: data[0].pfive,
    //             psix: data[0].psix,
    //             pseven: data[0].pseven,
    //             peight: data[0].peight,
    //             pnine: data[0].pnine,
    //             pten: data[0].pten
    //         })});
    //     }
    // }, [refresh]);

    if (props.article === 'index'){
        return (
            <>
                <div className="container">
                    <h1>Nevada Corporate Services</h1>
                    <h4>Welcome to Nevada Corporation Services, your one-stop shop for incorporating in the great state of Nevada!</h4>
                    <p>Are you looking to start a new business or incorporate an existing one? Look no further than Nevada, the premier state for business incorporation. Our team of experienced professionals can help guide you through the process of creating a corporation in Nevada, taking advantage of all the benefits the state has to offer.</p>
                    <p>One of the main benefits of incorporating in Nevada is the state's tax advantages. Nevada has no state income tax, no franchise tax, and no tax on corporate shares, which means you can keep more of your hard-earned profits. Additionally, Nevada has some of the lowest property tax rates in the country, making it an ideal location for businesses looking to reduce their tax burden.</p>
                    <p>But tax benefits are just the tip of the iceberg when it comes to incorporating in Nevada. Our state offers robust asset protection laws, which can help shield your personal assets from business liabilities. Nevada corporations also have fewer reporting requirements, making it easier to run your business without excessive bureaucracy.</p>
                    <p>Incorporating in Nevada also provides increased privacy for business owners. Our state does not require public disclosure of the names of officers and directors of a corporation, so you can maintain anonymity if desired.</p>
                    <p>At Nevada Corporation Services, we offer a variety of incorporation packages to meet your business needs. Our experienced team can help you navigate the process, from selecting the right type of corporation to submitting your paperwork and registering your business with the state.</p>
                    <p>Thank you for considering Nevada Corporation Services for your business incorporation needs. Contact us today to learn more about the benefits of incorporating in Nevada and how we can help you get started on the path to success.</p>
                </div>
            </>
        );
    } else if (props.article === 'whatllc'){
        return (
            <>
                <div className="container">
                    <h1>What's an LLC?</h1>
                    <h4>Welcome to our LLC informational page! Here we'll explain what an LLC is and why it might be the right business structure for you.</h4>
                    <p>LLC stands for Limited Liability Company. It's a type of business structure that combines the liability protection of a corporation with the tax benefits of a partnership or sole proprietorship. Essentially, an LLC is a separate legal entity that shields its owners, or "members," from personal liability for the company's debts and liabilities.</p>
                    <hr />
                    <h4>Why form an LLC?</h4>
                    <p>Forming an LLC offers several benefits over other business structures. First and foremost, it protects your personal assets. In the event that your LLC incurs debts or legal issues, your personal assets (such as your home or car) are generally shielded from creditors or legal judgments. This provides added peace of mind and financial security for business owners.</p>
                    <p>LLCs also offer more flexibility and simplicity than corporations. They typically have fewer reporting and management requirements than corporations, and members can participate in the management of the business without being subject to the same formalities required of corporate directors and officers. Additionally, LLCs offer pass-through taxation, meaning that the profits and losses of the business flow through to the owners' personal tax returns, which can result in significant tax savings.</p>
                    <hr />
                    <h4>How do you form an LLC?</h4>
                    <p>The process for forming an LLC varies by state, but generally involves filing articles of organization with the state's secretary of state, and paying a fee. In addition, you'll need to choose a name for your LLC, designate a registered agent, and determine how the LLC will be managed (either by members or by designated managers).</p>
                    <p>Once your LLC is formed, you'll need to obtain any necessary licenses and permits, and comply with state and federal regulations. It's also important to keep accurate records and maintain your LLC in good standing by filing annual reports and paying any necessary fees.</p>
                    <p>Overall, forming an LLC can be a great choice for small business owners looking for liability protection, flexibility, and tax benefits. If you're interested in forming an LLC, be sure to consult with an attorney or accountant to ensure that you fully understand the process and requirements.</p>
                </div>
            </>
        );
    } else if (props.article === 'whatcorp'){
        return (
            <>
                <div className="container">
                    <h1>What is a Corporation?</h1>
                    <h4>Welcome to our corporation informational page! Here we'll explain what a corporation is and why it might be the right business structure for you.</h4>
                    <p>A corporation is a type of business structure that is owned by shareholders and run by a board of directors. Unlike other business structures such as partnerships and sole proprietorships, a corporation is considered a separate legal entity from its owners, meaning that it has its own rights and responsibilities, and can enter into contracts, own property, and sue or be sued in its own name.</p>
                    <hr />
                    <h4>Why form a Corporation?</h4>
                    <p>Forming a corporation can offer several benefits over other business structures. First and foremost, it protects the personal assets of the shareholders. In the event that the corporation incurs debts or legal issues, the shareholders are not personally liable for the company's obligations. This provides added peace of mind and financial security for business owners.</p>
                    <p>Corporations can also raise capital more easily than other business structures. They can sell stock to investors in order to raise funds, and are generally seen as more stable and trustworthy than other business structures, which can make them more attractive to investors.</p>
                    <p>Corporations also offer more flexibility and structure than other business structures. They typically have formal management structures, with a board of directors and officers, which can help ensure that the business is run efficiently and effectively. They may also have more opportunities for growth and expansion, since they can sell stock to raise funds and hire employees.</p>
                    <hr />
                    <h4>How do you form a Corporation?</h4>
                    <p>The process for forming a corporation varies by state, but generally involves filing articles of incorporation with the state's secretary of state, and paying a fee. In addition, you'll need to choose a name for your corporation, designate a registered agent, and determine how the corporation will be managed. You'll also need to create bylaws that set out the rules and regulations for how the corporation will operate.</p>
                    <p>Once your corporation is formed, you'll need to obtain any necessary licenses and permits, and comply with state and federal regulations. It's also important to keep accurate records and maintain your corporation in good standing by holding regular board meetings and filing annual reports.</p>
                    <p>Overall, forming a corporation can be a great choice for business owners looking for liability protection, flexibility, and the ability to raise capital. If you're interested in forming a corporation, be sure to consult with an attorney or accountant to ensure that you fully understand the process and requirements.</p>
                </div>
            </>
        );
    } else if (props.article === 'procon'){
        return (
            <>
                <div className="container">
                    <h1>Corporations vs. LLCs</h1>
                    <h4>Advantages and Disadvantages</h4>
                    <h5>Limited Liability Companies</h5>
                    <p>LLCs (Limited Liability Companies) are a popular business structure because they offer a combination of liability protection, flexibility, and tax benefits. However, like any business structure, there are both advantages and disadvantages to forming an LLC. Here are some of the main ones:</p>
                    <p>Advantages of an LLC:</p>
                    <ol>
                        <li>Limited Liability Protection: LLCs offer personal liability protection for their owners, meaning that the owners are not personally responsible for the company's debts or legal issues. This means that their personal assets, such as their home or car, are generally not at risk if the LLC is sued or incurs debts.</li>
                        <li>Tax Flexibility: LLCs offer pass-through taxation, which means that the profits and losses of the business flow through to the owners' personal tax returns. This can result in significant tax savings, as the owners are only taxed on their share of the profits.</li>
                        <li>Flexible Management: LLCs offer more flexibility than other business structures, as the owners can participate in the management of the business without being subject to the same formalities required of corporate directors and officers.</li>
                        <li>Easy to Form: LLCs are relatively easy to form and require fewer formalities than corporations. In most states, you can form an LLC by filing articles of organization with the state and paying a fee.</li>
                    </ol>
                    <hr />
                    <p>Disadvantages of an LLC:</p>
                    <ol>
                        <li>Limited Life: LLCs are often seen as a short-term business structure because they typically have a limited life span. In many states, an LLC must be dissolved after a certain period of time or upon the death of one of the owners.</li>
                        <li>Self-Employment Taxes: While LLCs offer pass-through taxation, owners may still be subject to self-employment taxes on their share of the profits.</li>
                        <li>Complexity: While LLCs are generally less complex than corporations, they can still be more complex than other business structures such as sole proprietorships or partnerships. This can result in higher costs for formation and ongoing management.</li>
                        <li>Difficulty Raising Capital: LLCs may have difficulty raising capital because they cannot sell stock in the same way that corporations can. This can make it harder for LLCs to attract investors and raise funds for growth and expansion.</li>
                    </ol>
                    <p>Overall, the advantages of an LLC often outweigh the disadvantages for small business owners looking for liability protection, flexibility, and tax benefits. However, it's important to carefully consider the pros and cons and consult with an attorney or accountant before deciding on a business structure.</p>
                    <hr/>
                    <h5>Corporations</h5>
                    <p>Corporations are a popular business structure for both small and large companies. They offer several benefits over other business structures, but they also come with some drawbacks. Here are some of the main advantages and disadvantages of forming a corporation:</p>
                    <p>Advantages of a Corporation</p>
                    <ol>
                        <li>Limited Liability Protection: The primary advantage of forming a corporation is the limited liability protection it provides to its owners. Shareholders are not personally responsible for the corporation's debts or legal issues, which means that their personal assets are generally not at risk if the corporation is sued or incurs debts.</li>
                        <li>Easier Access to Capital: Corporations can raise capital more easily than other business structures, since they can sell stock to investors in order to raise funds. This makes it easier for corporations to finance growth and expansion.</li>
                        <li>Perpetual Existence: A corporation has perpetual existence, meaning that it can continue to operate even if one or more of its shareholders dies or leaves the company.</li>
                        <li>Professional Image: Corporations are often viewed as more professional and established than other business structures, which can help build credibility with customers, suppliers, and investors.</li>
                    </ol>
                    <p>Disadvantages of a Corporation</p>
                    <ol>
                        <li>Complexity and Formality: Corporations are more complex and formal than other business structures, and they require more paperwork, record-keeping, and legal and accounting expertise. This can result in higher formation and ongoing management costs.</li>
                        <li>Double Taxation: Corporations are subject to double taxation, meaning that they are taxed on their profits at the corporate level, and then shareholders are taxed on their dividends and capital gains. This can result in higher tax bills for both the corporation and its shareholders.</li>
                        <li>Loss of Control: Shareholders of a corporation may have less control over the company than owners of other business structures, since the corporation is run by a board of directors.</li>
                        <li>Compliance Requirements: Corporations are subject to more regulatory and compliance requirements than other business structures, which can result in higher costs and more time-consuming paperwork.</li>
                    </ol>
                    <p>Overall, corporations are a good choice for businesses that need to raise capital, want to protect their owners from personal liability, and are willing to comply with the formalities and regulations that come with this business structure. However, they are not the best choice for all businesses, and owners should carefully consider the advantages and disadvantages before deciding on a business structure. It's important to consult with an attorney or accountant to fully understand the pros and cons of forming a corporation.</p>
                </div>
            </>
        );
    } else if (props.article === 'whatbizpart'){
        return (
            <>
                <div className="container">
                    <h1>What is a Business Partnership?</h1>
                    <p>A business partnership is a type of business structure in which two or more individuals or entities, such as companies, join together to run a business. In a partnership, each partner contributes capital, skills, labor, or property, and they share in the profits, losses, and management of the business.</p>
                    <p>There are two main types of partnerships: general partnerships and limited partnerships. In a general partnership, all partners have equal management authority and are personally liable for the debts and legal issues of the business. In a limited partnership, there are two types of partners: general partners who have management authority and personal liability, and limited partners who have limited liability and do not participate in management.</p>
                    <p>Partnerships are a popular business structure for small businesses, particularly those with multiple owners who want to share in the risks and rewards of running a business. Partnerships offer several advantages, including shared decision-making and financial resources, flexibility in management and ownership structure, and pass-through taxation (in which profits and losses are reported on the partners' personal tax returns).</p>
                    <p>However, partnerships also come with some potential drawbacks. Partners may have disagreements over management or finances, and personal liability for the business's debts and legal issues can be a significant risk. Additionally, partnerships may have a limited lifespan if one or more partners leave the business or pass away, which can create additional management and ownership issues.</p>
                    <p>Overall, partnerships can be a good choice for small businesses with multiple owners who want to share in the management and profits of the business, but it's important to carefully consider the advantages and disadvantages before deciding on a business structure. It's recommended to consult with an attorney or accountant to fully understand the pros and cons of forming a partnership.</p>
                </div>
            </>
        );
    } else if (props.article === 'whatsole'){
        return (
            <>
                <div className="container">
                    <h1>What is a Sole Proprietorship?</h1>
                    <p>A sole proprietorship is a type of business structure in which an individual owns and operates a business as a single-person entity. In other words, the business is not a separate legal entity from the owner, and the owner is responsible for all aspects of the business, including its profits and losses.</p>
                    <p>As a sole proprietor, the owner has complete control over the business, and there is no separation between the business's assets and the owner's personal assets. This means that the owner is personally liable for any debts or legal issues that the business incurs.</p>
                    <p>Sole proprietorships are one of the simplest and most common business structures, and they are often used by small businesses and self-employed individuals. This is because they are easy and inexpensive to set up and maintain, with few legal formalities or regulatory requirements.</p>
                    <p>Additionally, as a sole proprietor, the owner has full ownership and control of the business, including all profits generated by the business. The owner can also make decisions quickly and easily without having to consult with partners or a board of directors.</p>
                    <p>However, there are some disadvantages to operating as a sole proprietorship. For example, the owner's personal assets are at risk if the business faces financial or legal problems. Additionally, it may be difficult to raise capital or obtain financing as a sole proprietor since lenders may view the business as less stable or less able to pay back loans.</p>
                    <p>Overall, a sole proprietorship can be a good choice for individuals who want to start and run a small business on their own, but it's important to carefully consider the advantages and disadvantages before deciding on a business structure. It's recommended to consult with an attorney or accountant to fully understand the pros and cons of forming a sole proprietorship.</p>
                </div>
            </>
        );
    } else if (props.article === 'whatnon'){
        return (
            <>
                <div className="container">
                    <h1>What is a Non-Profit?</h1>
                    <p>A nonprofit, or not-for-profit, is a type of organization that does not distribute any profits to its members, shareholders, or owners, but instead uses its income to fulfill its mission and serve its community. Nonprofits are often focused on a particular cause or social mission, such as education, healthcare, the environment, or social justice.</p>
                    <p>Nonprofits are organized as corporations or associations, and are often granted tax-exempt status by the government, meaning they are not required to pay federal income taxes on their income. This status is usually granted to organizations that serve a charitable, educational, religious, or scientific purpose.</p>
                    <p>Nonprofits can be funded by a variety of sources, including donations, grants, and fees for services provided. They are typically governed by a board of directors or trustees, who oversee the organization's operations and ensure that it is fulfilling its mission.</p>
                    <p>Nonprofits have several advantages over for-profit businesses, such as access to grants and donations, tax exemptions, and a sense of purpose that can attract passionate volunteers and employees. However, they also face challenges, such as a lack of funding, limited resources, and the need to continually demonstrate their impact and effectiveness to donors and the community.</p>
                    <p>Overall, nonprofits play an important role in society by addressing important social and community needs, and providing essential services and support to vulnerable populations. They are a vital part of the economy and provide an opportunity for individuals and groups to make a meaningful difference in their communities.</p>
                </div>
            </>
        );
    } else if (props.article === 'states'){
        return (
            <>
                <div className="container">
                    <h1>Where should you form your business?</h1>
                    <p>When it comes to starting a business, choosing the right state to incorporate in can have a significant impact on your tax burden and overall business success. Each state has its own set of tax laws, regulations, and business incentives that can affect the profitability of your business. In this article, we will rank all 50 states based on their business tax benefits and compare each state to Nevada, which is known for its favorable business climate.</p>
                    <hr />
                    <h4>Nevada</h4>
                    <p>Nevada is consistently ranked as one of the top states for business tax benefits. The state has no personal or corporate income tax, no franchise tax, and no inventory tax. Additionally, Nevada has low property tax rates and no unitary tax, making it an attractive location for businesses of all sizes.</p>
                    <hr />
                    <h4>Wyoming</h4>
                    <p>Wyoming is another state that offers a favorable tax climate for businesses. The state has no corporate or individual income tax, and it has one of the lowest sales tax rates in the country. Additionally, Wyoming has a low property tax rate and offers various business incentives and exemptions.</p>
                    <hr />
                    <h4>South Dakota</h4>
                    <p>South Dakota is a tax-friendly state for businesses, with no corporate or individual income tax, no personal property tax, and a low sales tax rate. The state also has various business incentives, including tax credits for new job creation and property tax exemptions for certain types of property.</p>
                    <hr />
                    <h4>Alaska</h4>
                    <p>Alaska has no individual or corporate income tax, no statewide sales tax, and no personal property tax. Additionally, the state offers various tax credits and incentives for businesses, such as the Alaska Economic Development Grant and the Alaska Industrial Development and Export Authority.</p>
                    <hr />
                    <h4>Florida</h4>
                    <p>Florida is a popular destination for businesses due to its favorable tax climate. The state has no individual income tax, no corporate income tax on limited liability companies, and a low sales tax rate. Additionally, Florida offers various business incentives and exemptions, including the Qualified Target Industry Tax Refund and the Capital Investment Tax Credit.</p>
                    <hr />
                    <h4>Montana</h4>
                    <p>Montana has no sales tax and no gross receipts tax, making it a tax-friendly state for businesses. Additionally, Montana has a low individual income tax rate and various business incentives and exemptions, such as the Big Sky Economic Development Trust Fund and the Montana Coal Tax Trust Fund.</p>
                    <hr />
                    <h4>New Hampshire</h4>
                    <p>New Hampshire has no sales tax or individual income tax, but it does have a corporate income tax. However, the state offers various business incentives and exemptions, including the Job Training Fund and the Research and Development Tax Credit.</p>
                    <hr />
                    <h4>Texas</h4>
                    <p>Texas has no individual income tax, and it is known for its business-friendly climate. The state has a low corporate income tax rate, and it offers various business incentives and exemptions, such as the Texas Enterprise Fund and the Texas Emerging Technology Fund.</p>
                    <hr />
                    <h4>Utah</h4>
                    <p>Utah has a low corporate income tax rate, and it offers various business incentives and exemptions, such as the Economic Development Tax Increment Financing program and the Industrial Assistance Fund. The state also has a low sales tax rate and no inventory tax.</p>
                    <hr />
                    <h4>Colorado</h4>
                    <p>Colorado has a relatively low corporate income tax rate, and it offers various business incentives and exemptions, such as the Job Growth Incentive Tax Credit and the Advanced Industry Investment Tax Credit. The state also has a low property tax rate and no inventory tax.</p>
                    <hr />
                    <p>Overall, when it comes to starting a business, the state you choose to incorporate in can have a significant impact on your tax burden and overall business success. While there are many factors to consider when selecting a state to incorporate in, it's important to weigh the tax benefits and business incentives of each state to determine which one is right for your business. Nevada is consistently ranked as one of the top states for business tax benefits, and it's worth considering as a location for your business.</p>
                </div>
            </>
        );
    } else if (props.article === 'whatlivtrust'){
        return (
            <>
                <div className="container">
                    <h1>What is a Living Trust?</h1>
                    <p>A living trust is a legal document that is created during a person's lifetime to manage their assets and property. The person who creates the trust is called the grantor, and they transfer their assets into the trust, which is managed by a trustee. The grantor can also be the trustee of their own living trust.</p>
                    <p>A living trust is different from a will because it takes effect during the grantor's lifetime, and it can continue to manage their assets even after their death. The trustee is responsible for managing the assets in the trust and distributing them to the beneficiaries according to the grantor's instructions.</p>
                    <h4>Advantages of Creating a Living Trust</h4>
                    <ol>
                        <li><strong>Avoiding probate</strong> - Since the assets in the trust are not part of the grantor's probate estate, they can be distributed to the beneficiaries without going through the costly and time-consuming probate process.</li>
                        <li><strong>Privacy</strong> - Unlike a will, which becomes part of the public record, a living trust is a private document that is not filed with the court.</li>
                        <li><strong>Flexibility</strong> - The grantor can modify or revoke the living trust at any time during their lifetime.</li>
                        <li><strong>Management of assets</strong> - If the grantor becomes incapacitated, the trustee can manage the assets in the trust on their behalf, avoiding the need for a court-appointed guardian.</li>
                    </ol>
                    <hr />
                    <h4>Disadvantages of Creating a Living Trust</h4>
                    <ol>
                        <li><strong>Cost</strong> - Creating a living trust can be more expensive than creating a will, as it requires the transfer of assets into the trust and the ongoing management of the trust by a trustee.</li>
                        <li><strong>Time-consuming</strong> - Creating a living trust can be a time-consuming process, as it involves transferring assets into the trust and creating a plan for managing those assets.</li>
                        <li><strong>Not a complete substitute for a will</strong> - While a living trust can help avoid probate, it is still important to have a will to address other issues, such as the appointment of a guardian for minor children.</li>
                    </ol>
                    <p>Overall, a living trust can be a useful tool for managing assets and avoiding probate, but it is important to carefully consider the potential benefits and drawbacks before deciding whether to create one. It may be a good idea to consult with an attorney or financial planner to determine whether a living trust is right for you.</p>
                </div>
            </>
        );
    } else if (props.article === 'whatretrust'){
        return (
            <>
                <div className="container">
                    <h1>What is a Real Estate Privacy Trust?</h1>
                    <p>A real estate privacy trust, also known as a land trust or title holding trust, is a legal arrangement in which real estate is placed in a trust, and the beneficial interest in the trust is owned by a beneficiary. The trustee is the legal owner of the property and has the authority to manage and control the property on behalf of the beneficiary.</p>
                    <p>The primary purpose of a real estate privacy trust is to provide anonymity for the beneficiary. When the property is held in the trust, the trustee's name is listed as the owner of the property on public records, while the beneficiary's name is kept confidential. This allows the beneficiary to maintain their privacy and avoid unwanted attention or inquiries related to the property ownership.</p>
                    <p>Some of the benefits of using a real estate privacy trust include:</p>
                    <ul>
                        <li><strong>Enhanced privacy</strong> - The use of a real estate privacy trust allows the beneficiary to maintain their anonymity and keep their name off public records.</li>
                        <li><strong>Estate planning</strong> - A real estate privacy trust can be an effective estate planning tool, allowing for the easy transfer of the property to the beneficiary upon the death of the grantor.</li>
                        <li><strong>Asset protection</strong> - A real estate privacy trust can provide protection for the property against potential legal claims or creditors.</li>
                        <li><strong>Avoidance of probate</strong> - Since the property is held in the trust, it can be distributed to the beneficiary upon the grantor's death without going through probate.</li>
                        <li><strong>Flexibility</strong> - The trust can be easily modified or revoked by the grantor during their lifetime.</li>
                    </ul>
                    <p>It is important to note that a real estate privacy trust is not a complete substitute for legal advice, and it may not be appropriate for all situations. It is always recommended to consult with an attorney to determine if a real estate privacy trust is a suitable option for your specific circumstances.</p>
                </div>
            </>
        );
    } else if (props.article === 'bizcred'){
        return (
            <>
                <div className="container">
                    <h1>What is Corporate Credit?</h1>
                    <p>Corporate credit refers to the credit that a business can obtain in its own name, separate from the personal credit of its owners or shareholders. There are several benefits to having corporate credit, including:</p>
                    <ul>
                        <li>Separation of personal and business finances - By establishing corporate credit, a business can separate its financial activities from those of its owners or shareholders, reducing the risk of personal financial liability for the business's debts.</li>
                        <li>Access to financing - With good corporate credit, a business can qualify for loans and lines of credit, providing it with the capital it needs to operate and grow.</li>
                        <li>Lower interest rates - Businesses with good credit scores can often qualify for lower interest rates on loans and other forms of financing, reducing the cost of borrowing.</li>
                        <li>Better supplier terms - With good corporate credit, a business can negotiate better terms with suppliers, such as extended payment periods or discounts for early payment.</li>
                        <li>Improved cash flow - By establishing credit, a business can improve its cash flow by deferring payments for goods and services until a later date.</li>
                        <li>Increased credibility - Having good credit can enhance a business's reputation and credibility, making it more attractive to investors and customers.</li>
                    </ul>
                    <p>Overall, having corporate credit can provide a number of benefits for a business, including increased access to financing, improved cash flow, and enhanced credibility. It is important for businesses to establish and maintain good credit in order to take advantage of these benefits and ensure long-term success.</p>
                    <hr />
                    <h1>Credit Options</h1>
                    <p>We specialize in helping businesses obtain the credit they need to succeed. We understand that securing financing can be a challenge for businesses of all sizes, and we're here to help.</p>
                    <p>We offer a range of business credit products designed to meet the needs of companies in various industries. Our team of financial experts can help you determine which type of credit is best for your business, and work with you to ensure that you meet the requirements for approval.</p>
                    <h2>Here are just a few of the business credit options that we offer:</h2>
                    <ul>
                        <li>Business lines of credit - A line of credit allows businesses to access funds as needed, up to a predetermined limit. This type of credit is ideal for businesses that have fluctuating cash flow needs, as it provides a flexible source of financing.</li>
                        <li>Business credit cards - Business credit cards offer a convenient way to pay for expenses and build credit. With features such as cashback rewards and expense tracking tools, these cards can be a valuable tool for businesses of all sizes.</li>
                        <li>Equipment financing - For businesses that need to purchase or lease equipment, we offer equipment financing options. This type of credit allows businesses to acquire the equipment they need without having to pay for it upfront.</li>
                        <li>Invoice financing - Invoice financing allows businesses to borrow against their outstanding invoices, providing a source of working capital while waiting for customers to pay.</li>
                    </ul>
                    <p>We understand that each business has unique needs, and we work closely with our clients to ensure that they get the credit products that are right for them. Our goal is to help businesses thrive by providing them with the financing they need to grow and succeed.</p>
                    <p>If you're interested in learning more about our business credit options, please browse our website or contact us today. We're here to help you succeed.</p>
                </div>
            </>
        );
    } else if (props.article === 'estate'){
        return (
            <>
                <div className="container">
                    <h1>What is Estate Planning?</h1>
                    <p>Estate planning is the process of creating a plan for the management and distribution of your assets after your death. This can include preparing a will, creating trusts, and designating beneficiaries for retirement accounts and life insurance policies. Here are some benefits of estate planning:</p>
                    <ul>
                        <li>Asset protection - Proper estate planning can help protect your assets from creditors, lawsuits, and other legal issues that can arise after your death.</li>
                        <li>Minimize taxes - With proper estate planning, you can reduce the amount of taxes that your heirs will have to pay, ensuring that they receive as much of your estate as possible.</li>
                        <li>Avoid probate - By creating a living trust or other estate planning tools, you can avoid probate, which can be a costly and time-consuming process.</li>
                        <li>Provide for your family - Estate planning allows you to ensure that your family is provided for after your death. You can designate guardians for minor children, create trusts to provide for family members with special needs, and ensure that your loved ones are taken care of financially.</li>
                        <li>Maintain privacy - By creating a living trust, you can ensure that your assets and their distribution remain private, as living trusts are not subject to public record.</li>
                        <li>Peace of mind - Knowing that you have a plan in place for the management and distribution of your assets can provide peace of mind, both for you and your loved ones.</li>
                    </ul>
                    <p>Overall, estate planning is an important step for anyone who wants to protect their assets, provide for their family, and ensure that their wishes are carried out after their death. By working with an experienced estate planning attorney, you can create a plan that meets your specific needs and provides peace of mind for you and your loved ones.</p>
                </div>
            </>
        );
    } else if (props.article === '401k'){
        return (
            <>
                <div className="container">
                    <h1>What is a 401k Direct?</h1>
                    <p>A 401(k) plan is a retirement savings plan that allows employees to contribute a portion of their earnings into a tax-advantaged investment account. There are two main types of 401(k) plans: traditional 401(k) plans and Roth 401(k) plans. In this article, we will focus on the traditional 401(k) plan, specifically the 401k direct option.</p>
                    <p>A 401k direct plan is a type of 401(k) plan that allows employees to direct their own investments. With a 401k direct plan, employees have the ability to choose their own investments from a range of options provided by their employer. This is in contrast to other types of 401(k) plans, where investments are chosen and managed by a financial advisor or the plan sponsor.</p>
                    <p>The benefits of a 401k direct plan include:</p>
                    <ul>
                        <li>Greater control - With a 401k direct plan, employees have greater control over their investments. They can choose the specific stocks, bonds, mutual funds, or other investments that they believe will best meet their individual financial goals.</li>
                        <li>Lower fees - Because employees are managing their own investments, the fees associated with a 401k direct plan are often lower than those associated with other types of 401(k) plans.</li>
                        <li>Flexibility - With a 401k direct plan, employees have the flexibility to adjust their investments as needed, based on changes in the market or in their personal financial goals.</li>
                        <li>Tax benefits - Like other types of 401(k) plans, a 401k direct plan offers tax benefits, including the ability to defer taxes on contributions until retirement, when tax rates may be lower.</li>
                    </ul>
                    <p>It's important to note that a 401k direct plan may not be the best option for everyone. Managing investments requires a certain level of financial knowledge and experience, and not everyone may feel comfortable making investment decisions on their own. Additionally, a 401k direct plan may not be offered by all employers.</p>
                    <p>Overall, a 401k direct plan can be a valuable tool for employees who want greater control over their retirement savings and have the financial knowledge and experience to manage their own investments. It's important to carefully consider your individual financial goals and needs when choosing the right retirement savings plan for you.</p>
                </div>
            </>
        );
    } else if (props.article === 'assetpro'){
        return (
            <>
                <div className="container">
                    <h1>What is Asset Protection?</h1>
                    <p>Asset protection is the practice of safeguarding one's wealth and assets from legal claims, creditor actions, and potential loss due to various risks such as lawsuits, bankruptcy, or divorce. The goal of asset protection is to protect one's assets from being taken or significantly reduced in value due to unexpected events or claims.</p>
                    <p>There are many different strategies and tools available to achieve asset protection, including the creation of legal entities such as corporations, limited liability companies (LLCs), trusts, and the use of insurance policies.</p>
                    <p>The benefits of asset protection can include:</p>
                    <ul>
                        <li>Protection from creditors - Asset protection strategies can shield assets from creditors, protecting them from being seized or reduced in value due to legal claims or debt collection efforts.</li>
                        <li>Protection from lawsuits - Asset protection can protect assets from being lost or significantly reduced in value due to lawsuits or other legal claims.</li>
                        <li>Tax benefits - Certain asset protection strategies can offer tax benefits, such as reducing estate taxes or providing tax deductions for contributions to certain types of trusts or retirement plans.</li>
                        <li>Peace of mind - Asset protection can provide peace of mind and security knowing that one's assets are protected and their financial future is secure.</li>
                        <li>Estate planning benefits - Asset protection can also be a key component of estate planning, helping to ensure that one's assets are passed on to their beneficiaries according to their wishes.</li>
                    </ul>
                    <p>It's important to note that asset protection strategies must be established before a legal claim or other risk arises. Attempting to shield assets after a claim or legal action has been taken can be seen as fraudulent and may not be effective.</p>
                    <p>Failing to protect your assets can lead to a range of potential dangers, including:</p>
                    <ul>
                        <li>Lawsuits: If you operate a business or own property, you are at risk of being sued. A lawsuit can result in a significant financial loss and even bankruptcy if you are found liable for damages.</li>
                        <li>Personal Liability: If you do not have proper asset protection in place, your personal assets, such as your home and savings, could be at risk if your business is sued.</li>
                        <li>Creditors: If you owe money to creditors, they may be able to go after your personal assets if you have not taken steps to protect them.</li>
                        <li>Divorce: If you go through a divorce, your assets could be at risk if they are not properly protected. Your spouse may be entitled to a portion of your assets, which could result in significant financial losses.</li>
                        <li>Estate Planning: If you do not have a proper estate plan in place, your assets could be subject to estate taxes and may not be distributed according to your wishes.</li>
                    </ul>
                    <p>In summary, not protecting your assets can result in serious financial and legal consequences. By taking steps to protect your assets, such as setting up a business entity, creating a trust, and developing an estate plan, you can help to safeguard your assets and minimize the risks of financial loss.</p>
                    <p>Overall, asset protection is an important consideration for anyone looking to protect their wealth and assets. It's important to work with a qualified attorney or financial advisor to determine the best asset protection strategies for your individual needs and circumstances.</p>
                </div>
            </>
        );
    } else if (props.article === 'nvadd'){
        return (
            <>
                <div className="container">
                    <h1>Why should you have a bank and address in Nevada?</h1>
                    <p>If you own a Nevada corporation and operate it from outside of Nevada, you may consider creating a virtual office and bank account in Nevada. A virtual office is a service that provides a physical business address, mail handling, phone answering, and other services without requiring you to have a physical office space. Here are the steps you can follow to create a virtual office and bank account in Nevada for your out-of-state-owned Nevada corporation:</p>
                    <ol>
                        <li>Choose a virtual office provider - Research and choose a virtual office provider that meets your needs and budget. Look for providers that offer a physical address in Nevada, mail forwarding, and phone answering services.</li>
                        <li>Sign up for the virtual office service - Once you have chosen a provider, sign up for their virtual office service and provide the necessary identification and business documents. You will typically need to provide proof of identity and business formation documents for your Nevada corporation.</li>
                        <li>Register your corporation in Nevada - If your corporation is not already registered in Nevada, you will need to register it with the Nevada Secretary of State. This will require filing the appropriate paperwork and paying the necessary fees.</li>
                        <li>Apply for a bank account - With your virtual office and corporation set up, you can then apply for a bank account at a local Nevada bank. You will need to provide the bank with your corporation's formation documents, your personal identification, and proof of your virtual office address.</li>
                        <li>Maintain compliance - Once you have established your virtual office and bank account in Nevada, it is important to maintain compliance with state and federal regulations. This includes filing any required tax returns, maintaining corporate records, and adhering to any licensing requirements for your specific industry.</li>
                    </ol>
                    <p>Creating a virtual office and bank account in Nevada can provide several benefits for your out-of-state-owned corporation, including access to Nevada's business-friendly tax and regulatory environment. However, it is important to work with a qualified attorney or financial advisor to ensure that you are meeting all legal and regulatory requirements and maximizing the benefits of your Nevada corporation.</p>
                </div>
            </>
        );
    } else if (props.article === 'bizplan'){
        return (
            <>
                <div className="container">
                    <h1>What is a Business Plan?</h1>
                    <p>A business plan is a comprehensive document that outlines the objectives, strategies, and financial projections of a business. It is a crucial tool for entrepreneurs and small business owners to attract investors, secure financing, and set a clear roadmap for their business. If you are considering starting a new business or expanding an existing one, investing in a professional business plan can be an excellent decision. Here are some benefits of having a business plan:</p>
                    <ul>
                        <li>Helps clarify your vision - A business plan forces you to articulate your business idea in a clear and concise way. It can help you identify your target market, unique value proposition, and overall business goals.</li>
                        <li>Helps secure funding - Whether you are seeking funding from investors or applying for a loan, a comprehensive business plan is essential. A well-written plan can help you convince potential investors or lenders that your business is worth investing in.</li>
                        <li>Provides a roadmap for your business - A business plan outlines the steps you need to take to achieve your business goals. It includes detailed financial projections, marketing strategies, and operational plans.</li>
                        <li>Helps you make informed decisions - A business plan is a living document that can help you make informed decisions about your business. It allows you to track your progress, identify areas of improvement, and make adjustments to your strategies as needed.</li>
                        <li>Increases your chances of success - According to a study by the Small Business Administration, businesses with a written business plan are more likely to succeed than those without one. This is because a business plan forces you to think critically about your business and develop a strategic plan for success.</li>
                    </ul>
                    <p>Investing in a professional business plan can be a wise decision for any entrepreneur or small business owner. It can provide numerous benefits, including securing funding, clarifying your vision, and providing a roadmap for success. At our website, we offer customized business plans that are tailored to your specific business needs. Our team of experienced business plan writers will work closely with you to create a comprehensive plan that will help you achieve your business goals. Contact us today to learn more.</p>
                </div>
            </>
        );
    } else if (props.article === 'corpveil'){
        return (
            <>
                <div className="container">
                    <h1>What is the Corporate Veil?</h1>
                    <p>The corporate veil is a legal concept that separates the assets and liabilities of a corporation from those of its shareholders. The benefits of the corporate veil in Nevada are numerous, including:</p>
                    <ul>
                        <li>Limited Liability Protection: Shareholders of a Nevada corporation are not personally liable for the corporation's debts and liabilities. This means that creditors cannot go after the personal assets of the shareholders to satisfy corporate debts.</li>
                        <li>Tax Advantages: Nevada does not impose corporate income tax, franchise tax, or personal income tax on corporate shareholders. This provides a significant tax advantage over other states.</li>
                        <li>Business Flexibility: Nevada law provides greater flexibility for corporations in terms of governance, shareholder rights, and shareholder meetings. This allows businesses to operate in a way that best suits their needs.</li>
                        <li>Asset Protection: The corporate veil also provides asset protection for the corporation's assets. This means that in the event of a lawsuit or other legal action against the corporation, its assets are protected.</li>
                        <li>Privacy: Nevada provides greater privacy for corporate shareholders, as the names and addresses of shareholders do not have to be listed in the articles of incorporation.</li>
                    </ul>
                    <p>Overall, the benefits of the corporate veil in Nevada make it an attractive option for businesses looking to protect their assets, reduce their tax burden, and operate in a flexible and business-friendly environment.</p>
                    <p>Yes, the corporate veil can be pierced in Nevada, as in any other state, under certain circumstances. Piercing the corporate veil refers to a situation where a court disregards the separation between the corporation and its shareholders and holds the shareholders personally liable for the corporation's debts or legal obligations.</p>
                    <p>Under Nevada law, the corporate veil can be pierced if there is evidence of fraud, wrongdoing, or abuse of the corporate form. Specifically, the courts will look at whether there was a failure to observe corporate formalities, whether there was commingling of corporate and personal assets, and whether the corporation was used as a device to commit a fraud or injustice.</p>
                    <p>To pierce the corporate veil in Nevada, a plaintiff must show that:</p>
                    <ol>
                        <li>The corporation is being used as an alter ego of the shareholders;</li>
                        <li>The corporation was used to perpetrate a fraud or injustice;</li>
                        <li>The corporate veil must be pierced to prevent an injustice.</li>
                    </ol>
                    <p>Piercing the corporate veil is a complex legal issue that requires a thorough analysis of the facts and circumstances of each case. If you have concerns about the potential for piercing the corporate veil, it's important to consult with an experienced attorney who can provide guidance on the best course of action.</p>
                </div>
            </>
        );
    } else if (props.article === 'experts'){
        return (
            <>
                <div className="container">
                    <h1>Nevada Corporate Services</h1>
                    <p>At Nevada Corporate Services, we are experts in creating entities such as corporations and limited liability companies (LLCs). With years of experience and a deep understanding of the legal and business landscape, we are proud to be leaders in our industry.</p>
                    <p>Here are some reasons why we are the best choice for creating your entity:</p>
                    <ul>
                        <li>Experience: Our team of professionals has extensive experience in creating entities and guiding businesses through the process. We have worked with a variety of clients in a range of industries, so we are well-equipped to handle your specific needs.</li>
                        <li>Customized solutions: We understand that every business is unique, which is why we provide customized solutions tailored to your specific requirements. Our team will work with you to understand your business goals and help you choose the right entity structure to achieve them.</li>
                        <li>Expert guidance: Creating an entity involves navigating complex legal and financial regulations. Our team of experts is well-versed in these regulations and will provide you with the expert guidance you need to create a successful entity.</li>
                        <li>Efficiency: We understand that time is of the essence when creating an entity. That's why we strive to make the process as efficient as possible, without compromising on quality.</li>
                        <li>Ongoing support: Creating an entity is just the beginning. We provide ongoing support to our clients to help them manage their entity, stay compliant with legal requirements, and grow their business.</li>
                    </ul>
                    <p>At Nevada Corporate Services, we are committed to helping you achieve your business goals. Let us put our expertise to work for you and help you create the entity that is right for your business. Contact us today to learn more about our services.</p>
                </div>
            </>
        );
    } else if (props.article === 'mission'){
        return (
            <>
                <div className="container">
                    <h1>The Nevada Corporate Services Mission</h1>
                    <p>Our mission is to empower entrepreneurs and small business owners by providing them with the resources and guidance needed to form an LLC or corporation. We believe that starting a business should be simple, affordable, and accessible to all.</p>
                    <p>Our website is dedicated to providing clear and comprehensive information about the benefits and drawbacks of forming an LLC or corporation, as well as step-by-step guides on how to form a business in any state. We strive to make the process of forming a business as easy as possible, with helpful resources, tips, and tools that can help entrepreneurs succeed.</p>
                    <p>At our core, we are committed to helping entrepreneurs and small business owners achieve their dreams and reach their full potential. We believe that by offering affordable and accessible resources, we can help more entrepreneurs succeed in their endeavors and make a positive impact on their communities.</p>
                    <p>We are dedicated to providing exceptional customer service and support, with a team of experts who are passionate about helping entrepreneurs and small business owners. Our ultimate goal is to make it as easy as possible for anyone to start and run a successful business.</p>
                </div>
            </>
        )
    } else if (props.article === 'privacy'){
        return (
            <>
                <div className="container">
                    <h1>Privacy Policy</h1>
                    <h2>Information We Collect</h2>
                    <p>We collect personal information such as your name, address, phone number, email address, and payment information when you purchase a corporation on our website. We also collect non-personal information such as your IP address and browser type when you visit our website.</p>
                    <h2>How We Use Your Information</h2>
                    <p>We use your personal information to process your order and communicate with you about your purchase. We may also use your information to improve our website and customer service.</p>
                    <h2>How We Share Your Information</h2>
                    <p>We do not sell or rent your personal information to third parties. We may share your information with our service providers who help us fulfill your order, such as our payment processor and shipping provider. We may also share your information as required by law or to protect our rights or the rights of others.</p>
                    <h2>Your Choices</h2>
                    <p>You can choose not to provide us with certain information, but this may limit your ability to purchase corporations from our website. You can also opt out of receiving marketing communications from us by following the instructions in the communication.</p>
                    <h2>Security</h2>
                    <p>We take reasonable measures to protect your personal information from unauthorized access and use. However, no security measures are perfect and we cannot guarantee the security of your information.</p>
                    <h2>Changes to this Policy</h2>
                    <p>We may update this Privacy Policy from time to time. If we make significant changes, we will provide notice on our website and obtain your consent as required by law.</p>
                    <h2>Contact Us</h2>
                    <p>If you have any questions or concerns about our Privacy Policy, please contact us.</p>
                </div>
            </>
        )
    } else if (props.article === 'expert'){
        return (
            <>
                <div className="container">
                    <div>
                    <p>At Nevada Corporate Services, we are experts in creating entities such as corporations and limited liability companies (LLCs). With years of experience and a deep understanding of the legal and business landscape, we are proud to be leaders in our industry.</p>
                    <p>Here are some reasons why we are the best choice for creating your entity:</p>
                    <ul>
                        <li><strong>Experience:</strong> Our team of professionals has extensive experience in creating entities and guiding businesses through the process. We have worked with a variety of clients in a range of industries, so we are well-equipped to handle your specific needs.</li>
                        <li><strong>Customized solutions:</strong> We understand that every business is unique, which is why we provide customized solutions tailored to your specific requirements. Our team will work with you to understand your business goals and help you choose the right entity structure to achieve them.</li>
                        <li><strong>Expert guidance:</strong> Creating an entity involves navigating complex legal and financial regulations. Our team of experts is well-versed in these regulations and will provide you with the expert guidance you need to create a successful entity.</li>
                        <li><strong>Efficiency:</strong> We understand that time is of the essence when creating an entity. That's why we strive to make the process as efficient as possible, without compromising on quality.</li>
                        <li><strong>Ongoing support:</strong> Creating an entity is just the beginning. We provide ongoing support to our clients to help them manage their entity, stay compliant with legal requirements, and grow their business.</li>
                    </ul>
                    <p>At Nevada Corporate Services, we are committed to helping you achieve your business goals. Let us put our expertise to work for you and help you create the entity that is right for your business. Contact us today to learn more about our services.</p>
                    </div>
                </div>
            </>
        )
    }
}