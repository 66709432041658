import {Tab, Tabs} from 'react-bootstrap';
import CategoryCards from './catcards';
import llc from '../images/mtngoat.png';
import corp from '../images/lake.png';

export default function Enttabs(){
    return(
        <>
        <div className="container">
            <Tabs defaultActiveKey="LLC" id="entTabs" className="mb-3">
                <Tab eventKey="LLC" title="LLC" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>What's an LLC?</h1>
                                <h4>Welcome to our LLC informational page! Here we'll explain what an LLC is and why it might be the right business structure for you.</h4>
                                <p>LLC stands for Limited Liability Company. It's a type of business structure that combines the liability protection of a corporation with the tax benefits of a partnership or sole proprietorship. Essentially, an LLC is a separate legal entity that shields its owners, or "members," from personal liability for the company's debts and liabilities.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={llc} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row ">
                            <div className="col-6">
                                <CategoryCards cat="whyllc" />
                            </div>
                            <div className="col-6">
                                <CategoryCards cat="howllc" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 offset-2">
                                <CategoryCards cat="procon" />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="corp" title="Corporation" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>What's a Corporation?</h1>
                                <h4>Welcome to our corporation informational page! Here we'll explain what a corporation is and why it might be the right business structure for you.</h4>
                                <p>A corporation is a type of business structure that is owned by shareholders and run by a board of directors. Unlike other business structures such as partnerships and sole proprietorships, a corporation is considered a separate legal entity from its owners, meaning that it has its own rights and responsibilities, and can enter into contracts, own property, and sue or be sued in its own name.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={corp} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row ">
                            <div className="col-6">
                                <CategoryCards cat="whycorp" />
                            </div>
                            <div className="col-6">
                                <CategoryCards cat="howcorp" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 offset-2">
                                <CategoryCards cat="procon" />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="bizpart" title="Business Partnership" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>What's a Business Partnership?</h1>
                                <h4>Welcome to our business partnership informational page! Here we'll explain what a business partnership is and why it might be the right business structure for you.</h4>
                                <p>A business partnership is a type of business structure in which two or more individuals or entities, such as companies, join together to run a business. In a partnership, each partner contributes capital, skills, labor, or property, and they share in the profits, losses, and management of the business.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={corp} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row">
                            <div className="col-8 offset-2">
                                <CategoryCards cat="bizpart" />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="sole" title="Sole Proprietorship" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>What's a Sole Proprietorship?</h1>
                                <h4>Welcome to our sole proprietorship informational page! Here we'll explain what a sole proprietorship is and why it might be the right business structure for you.</h4>
                                <p>A sole proprietorship is a type of business structure in which an individual owns and operates a business as a single-person entity. In other words, the business is not a separate legal entity from the owner, and the owner is responsible for all aspects of the business, including its profits and losses.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={corp} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row">
                            <div className="col-8 offset-2">
                                <CategoryCards cat="sole" />
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="nonprof" title="Non-Profit" className="entities">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1>What's a Non-Profit?</h1>
                                <h4>Welcome to our non-profit informational page! Here we'll explain what a non-profit is and why it might be the right business structure for you.</h4>
                                <p>A nonprofit, or not-for-profit, is a type of organization that does not distribute any profits to its members, shareholders, or owners, but instead uses its income to fulfill its mission and serve its community. Nonprofits are often focused on a particular cause or social mission, such as education, healthcare, the environment, or social justice.</p>
                            </div>
                        </div>
                        <div className="row">
                            <img src={corp} alt="" className="w-100 entimg" />
                        </div>
                        <div className="row">
                            <div className="col-8 offset-2">
                                <CategoryCards cat="nonprof" />
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
        </>
    )
}