import React from 'react';
import {useState} from 'react';
import {Card, Button, Form, Alert} from 'react-bootstrap';

export default function Orderform(){
    const [order, setOrder] = useState({});
    const [updater, setUpdater] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleChange = (e) => {
        let changer = order;
        changer[e.target.name] = e.target.value;
        setOrder(changer);
        setUpdater(!updater);
        console.log(order[e.target.name]);
    }

    const handleVerify = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleSubmit(event);
        }
      
          setValidated(true);
    }

    const handleSubmit = () => {
        
        console.log(order);

        const pkg = order;

        fetch('/ordersubmit', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(pkg)
        })
        .then(console.log("Success"));
    }

    return(
        <>
        <div className="container orderform">
            <Form noValidate validated={validated} onSubmit={handleVerify} >
            <div className="row">
                <div className="col-8 offset-2 promobox">
                    <h3>Promotional Information</h3>
                    <Form.Group controlId="orderform">
                        <Form.Control required type="text" placeholder="Promo Code" name="promo" onChange={handleChange} />
                        <Form.Control required as="textarea" rows="5" placeholder="Special Notes" name="notes" onChange={handleChange} />
                        <Form.Control required type="text" placeholder="Quoted Price" name="quote" onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">Fix Errors Here</Form.Control.Feedback>
                    </Form.Group>
                </div>
            </div>
            {typeof order.promo == "string" && typeof order.notes == "string" && typeof order.quote == "string" &&
            <div className="row">
                <div className="col-6 personalbox">
                    <h3>About You</h3>
                    <Form.Group controlId="orderform">
                        <Form.Control required type="text" placeholder="Full Name" name="fullname" onChange={handleChange} />
                        <Form.Control required type="email" placeholder="Email Address (youremail@provider.com)" name="email" onChange={handleChange} />
                        <Form.Control required type="text" placeholder="Phone Number" name="phone" onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">Fix Errors Here</Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className="col-6 bizbox">
                    <h3>About Your Business</h3>
                    <Form.Group controlId="orderform">
                        <Form.Control required type="text" placeholder="Company Name" name="bizname" onChange={handleChange} />
                        <Form.Control required type="text" placeholder="Current URL/Website" name="bizurl" onChange={handleChange} />
                        <Form.Control required as="select" name="biztype" onChange={handleChange}>
                            <option>Select One...</option>
                            <option>Home Business</option>
                            <option>Real Estate</option>
                            <option>Retail</option>
                            <option value="Other">Other (please specify)</option>
                        </Form.Control>
                        {order.biztype === 'Other' &&
                        <Form.Control required type="text" placeholder="Type of Business" name="bizother" onChange={handleChange} />
                        }
                    </Form.Group>
                </div>
            </div>
            }
            {typeof order.fullname == "string" && typeof order.email == "string" && typeof order.phone == "string" &&
            <div className="row">
                <div className="col-6 paybox">
                    <h3>Payment Info</h3>
                    <Form.Group controlId="orderform">
                        <Form.Control required as="select" name="paytype" onChange={handleChange}>
                            <option>Select a Payment Method</option>
                            <option>Visa</option>
                            <option>Mastercard</option>
                            <option>Discover</option>
                            <option>American Express</option>
                            <option>Check</option>
                        </Form.Control>
                    </Form.Group>
                    {(order.paytype === 'Visa' || order.paytype === 'Mastercard' || order.paytype === 'Discover') &&
                    <>
                    <div className="row">
                        <div className="col-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Name on Card" name="cardname" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="Card Number" name="cardnum" onChange={handleChange} />
                                {order.cardnum !== undefined && order.cardnum.length !== 16 && validated &&
                                <Form.Text id="cardnumcheck" muted>Check Your Card Number</Form.Text>
                                }
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Form.Control required type="text" placeholder="Expiration Date" name="expire" onChange={handleChange} />
                        </div>
                        <div className="col-6">
                            <Form.Control required type="text" placeholder="Security Number" name="cvv" onChange={handleChange} />
                        </div>
                    </div>
                    </>
                    }
                    {order.paytype === 'American Express' &&
                    <>
                    <div className="row">
                        <div className="col-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Name on Card" name="cardname" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="Card Number" name="cardnum" onChange={handleChange} />
                                {order.cardnum !== undefined && order.cardnum.length !== 15 && validated &&
                                <Form.Text id="cardnumcheck" muted>Check Your Card Number</Form.Text>
                                }
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Form.Control required type="text" placeholder="Expiration Date" name="expire" onChange={handleChange} />
                        </div>
                        <div className="col-6">
                            <Form.Control required type="text" placeholder="Security Number" name="cvv" onChange={handleChange} />
                        </div>
                    </div>
                    </>
                    }
                    {order.paytype === 'Check' &&
                    <>
                    <div className="row">
                        <div className="col-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Name on Check" name="checkname" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="Name of Your Bank" name="bankname" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="Check Number" name="checknum" onChange={handleChange} />
                            </Form.Group>
                        </div>
                    </div>
                    </>
                    }
                </div>
                <div className="col-6 billbox">
                    <h3>Billing Address</h3>
                    <div className="row">
                        <div className="col-12">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Street Address" name="staddress" onChange={handleChange} />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="Bldg/Apt # (n/a if none)" name="stnumber" onChange={handleChange} />
                                <Form.Control required type="text" placeholder="State" name="state" onChange={handleChange} />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group controlId="orderform">
                                <Form.Control required type="text" placeholder="City" name="city" onChange={handleChange} />
                                <Form.Control required id="zipbox" type="text" placeholder="Zip Code" name="zipcode" onChange={handleChange} />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div> 
            }
            {typeof order.zipcode == "string" &&
            <Button type="button" block className="w-50 submission" onClick={handleSubmit}>Submit</Button> 
            }
            </Form>
        </div>
        </>
    )
}